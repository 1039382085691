body {
  margin: 0;
  font-family: 'Yu Gothic UI',Segoe\000020UI,Tahoma,Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Yu Gothic UI', "Segoe UI Semibold", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

h1 {
  font-size: 36px !important;
  font-weight: bold !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

h1, h2, h3, h4, h5, h6 {
  color: unset !important;
}
